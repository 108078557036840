
export function getSearchParameters (key = null) {
  const parameters = decodeURIComponent(window.location.search)
    .replace(/^\?/, '')
    .split('&')
    .filter(p => p)
    .map(p => {
      const split = p.split('=')
      return { key: split[0] || '', value: split[1] || '' }
    })

  if (parameters.map(p => p.key).includes(key)) return parameters.find(p => p.key === key).value
  else if (key !== null) return null
  else return parameters
}
function buildUrl (currentUrl, additionalParameter, value) {
  const url = new URL(currentUrl)
  const params = new URLSearchParams(url.search)
  if (params.has(additionalParameter)) {
    value = params.get(additionalParameter) + ';' + value
  }
  params.set(additionalParameter, value)

  url.search = params.toString()
  return url.toString()
}

function removeParameterFromUrl (currentUrl, parameter, value = null) {
  const url = new URL(currentUrl)
  const params = new URLSearchParams(url.search)

  if (value) {
    if (params.has(parameter)) {
      const values = params.get(parameter).split(';')
      if (values.length > 1) {
        values.splice(values.indexOf(value), 1)
        params.set(parameter, values.join(';'))
      } else {
        params.delete(parameter)
      }
    }
  } else {
    params.delete(parameter)
  }

  url.search = params.toString()
  return url.toString()
}

export default {
  buildUrl,
  removeParameterFromUrl
}
